import { ProductType } from "@app/shared/interface/product";
import {
  AppEnvironmentConfigTemplate,
  AppEnvironmentTemplate,
} from "@app/shared/interface/environment";

const basicScopes: string[] = [
  "openid",
  "email",
  "profile",
  "read:uber",
  "read:billing",
  "write:billing",
  "read:user-data",
  "write:user-data",
  "read:aca",
  "write:aca",
  "read:data-source",
  "write:data-source",
  "read:ssn-identity",
  "write:ssn-identity",
  "read:irs",
  "write:irs",
  "read:report-data",
  "write:report-data",
  "read:pdf-reports",
  "write:pdf-reports",
  "read:client-portal",
  "write:client-portal",
];

export const environment = (<AppEnvironmentConfigTemplate>{
  production: true,
  auth: {
    clientID: "REPLACE_AUTH0_CLIENTID", // Take Client ID from auth0 config -> Applications -> MS Angular 2 UI
    domain: "REPLACE_AUTH0_DOMAIN", // Take Domain from auth0 config -> Applications -> MS Angular 2 UI
    audience: "bencorp.microservices",
    redirect: "REPLACE_AUTH0_REDIRECT", //
    scope: basicScopes.join(" "),
    claimsPrefix: "https://bencorp.com",
    logoutUrl: "REPLACE_AUTH0_LOGOUTURL",
    connection: "Username-Password-Authentication",
  },
  marketplaceUrl: "REPLACE_LOGINURL",
  localStorageUrl: "REPLACE_MF_WEB_SERVER_URL",
  isMarketplace: false,
  services: { selfService: "self-service" },
  productType: ProductType.AFFORDABLE_CARE_ACT,
  firstAcaYear: 2015,
  productsUrls: {
    AFFORDABLE_CARE_ACT: "REPLACE_PRODUCTURL_AFFORDABLE_CARE_ACT",
    HOURS_TRACKING: "REPLACE_PRODUCTURL_HOURS_TRACKING",
    CLIENT_PORTAL: "REPLACE_PRODUCTURL_CLIENT_PORTAL",
    ORGANIZATION_VIEW: "REPLACE_PRODUCTURL_ORGANIZATION_VIEW",
  },
  hmr: false,
  reCAPTCHA: {
    siteKey: "6LeN0AEVAAAAAPEHlqu5DpydIUaoRHf1ksbk_zOu",
  },
  appVersion: "REPLACE_APP_VERSION",
  sentryDsn:
    "https://10cc54ad447c48fc8723aa38f73e3204@o530223.ingest.sentry.io/5660734",
  sentryEnvironment: "REPLACE_SENTRY_ENVIRONMENT",
  sentryRelease: "aca-pro@" + "REPLACE_APP_VERSION",
}) as AppEnvironmentTemplate;
