import { loadManifest } from "@angular-architects/module-federation";

export function appLoadManifest(environment) {
  return loadManifest(
    environment.production
      ? "/assets/mf.manifest.prod.REPLACE_APP_VERSION.json"
      : "/assets/mf.manifest.json",
    true
  );
}
