export interface Product {
  imageUrl: string;
  title: string;
  description: string;
  detailedDescription: string;
  productType: ProductType;
  productProvider: ProductProvider;
  paymentType: PaymentType;
  price?: Pricing;
  hasAccess: boolean;
}

export interface Pricing {
  productType: ProductType;
  productProvider: ProductProvider;
  paymentType: PaymentType;

  /**
   * Total amount of USD cents to be paid.
   */
  costInCents: number;
}

export enum ProductType {
  /**
   * MARKETPLACE products
   */
  AFFORDABLE_CARE_ACT = "AFFORDABLE_CARE_ACT",
  HOURS_TRACKING = "HOURS_TRACKING",
  DEPENDENTS_AUDIT = "DEPENDENTS_AUDIT",
  PENALTY_ASSESSMENT = "PENALTY_ASSESSMENT",
  CLIENT_PORTAL = "CLIENT_PORTAL",
  EMPLOYEE_PORTAL = "EMPLOYEE_PORTAL",
  ORGANIZATION_VIEW = "ORGANIZATION_VIEW",
  PRINT_SHOP = "PRINT_SHOP",

  /**
   * PDF_REPORT specific products
   */
  PDF_FORM = "PDF_FORM",
  PDF_COMPANY = "PDF_COMPANY",
}

export class ProductProvider {
  /**
   * PDF report printing functionality.
   */
  public static PDF_REPORT = "PDF_REPORT";

  public static MARKETPLACE = "MARKETPLACE";
}

export class PaymentType {
  public static MONTHLY = "MONTHLY";
  public static ONE_TIME = "ONE_TIME";
}
